export default function navigationItems() {
  return [
    {
      href: '/',
      name: 'Top',
    },
    {
      href: '/profile',
      name: 'Profile',
    },
    {
      href: '/works',
      name: 'Works',
    },
    {
      href: '/contact',
      name: 'Contact',
    },
    {
      href: '/libraries',
      name: 'Libraries',
    },
  ];
}
