import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import './contact.scss';

export default function Contact() {
  return (
    <motion.main
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ ease: 'easeOut', duration: 0.4 }}
      className="layout__main"
    >
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <div className="contact">
        <h1 className="contact__main-title">Contact</h1>
        <div className="contact__sns-frame">
          <a
            className="contact__sns-link"
            href="https://twitter.com/Siesta51"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="contact__sns-image"
              src="/images/twitter_icon.svg"
              alt="twitter"
            />
          </a>
          <a
            className="contact__sns-link"
            href="https://www.facebook.com/siesta.dalk"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="contact__sns-image"
              src="/images/facebook_icon.svg"
              alt="facebook"
            />
          </a>
        </div>
      </div>
    </motion.main>
  );
}
