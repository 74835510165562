import { Helmet } from 'react-helmet';
import RouterDefinition from '../../container/routerDefinition';
import Header from '../organisms/header';
import Footer from '../organisms/footer';
// import Background from '../common/background';
import headerInfo from '../../data/head';
import './default.scss';

export default function DefaultLayout({ children }) {
  return (
    <>
      <Helmet titleTemplate="%s | 外林のポートフォリオサイト">
        <html lang="jp" />
        <link rel="icon" href="/favicon.ico" />
        <title>{headerInfo.title}</title>
        <meta
          name="description"
          content="閲覧ありがとうございます。外林のポートフォリオサイトです。よろしくお願いいたします。"
        />
        <meta
          property="og:image"
          content={`https://og-image.vercel.app/${encodeURI(
            headerInfo.title
          )}.png?theme=light&md=0&fontSize=75px&images=https%3A%2F%2Fassets.vercel.com%2Fimage%2Fupload%2Ffront%2Fassets%2Fdesign%2Fnextjs-black-logo.svg`}
        />
        <meta name="og:title" content={headerInfo.title} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="layout">
        {/* <Background /> */}
        <Header />
        <RouterDefinition />
        <Footer />
      </div>
    </>
  );
}
