import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './index.css';
import App from './App';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      suspense: true,
    },
    mutations: {
      retry: 0,
    },
  },
});

const tagManagerArgs = {
  gtmId: 'GTM-PVJKZTJ',
};
TagManager.initialize(tagManagerArgs);

const googleAnalyticsOptions = [
  {
    trackingId: 'G-ZJTBR0HTHC',
    gaOptions: {
      debug_mode: process.env.GTM_FLAG,
    },
    gtagOptions: {
      debug_mode: process.env.GTM_FLAG,
    },
  },
];
ReactGA.initialize(googleAnalyticsOptions);

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <Router>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Router>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
