import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { selectWorkId } from '../../../state/works';
import { useQuery } from 'react-query';
import { getWorks } from '../../../api/works';
import { NotItems } from '../../organisms/not-items';
import { dateFormat } from '../../../container/common';
import './works.scss';

function Work({ id, title, projectAt, thumbnail }) {
  const setWorkId = useSetRecoilState(selectWorkId);

  return (
    <li className="work">
      <motion.div
        className="work__frame"
        layoutId={`work__frame--${id}`}
        onClick={() => setWorkId(id)}
      >
        <motion.div
          className="work__image-frame"
          layoutId={`work__image-frame--${id}`}
          style={{ backgroundImage: `url(${thumbnail.url})` }}
        />
        <motion.div
          className="work__info-frame"
          layoutId={`work__info-frame--${id}`}
        >
          <time className="work__date">{dateFormat(projectAt)}</time>
          <br />
          <h3 className="work__title">{title}</h3>
        </motion.div>
      </motion.div>
    </li>
  );
}
function SelectedWork({ id }) {
  const setWorkId = useSetRecoilState(selectWorkId);
  const { data: works = [] } = useQuery('works', () => getWorks());
  const v = works.contents.find((v) => v.id === id);

  return (
    <>
      <motion.div
        className="work__delete-info"
        onClick={() => setWorkId(null)}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.15 } }}
        transition={{ duration: 0.2, delay: 0.15 }}
        style={{ pointerEvents: 'auto' }}
      />
      <motion.div
        className="work__frame work__frame--open"
        layoutId={`work__frame--${id}`}
      >
        <div className="work__close-button" onClick={() => setWorkId(null)} />
        <motion.div
          className="work__image-frame"
          layoutId={`work__image-frame--${id}`}
          style={{ backgroundImage: `url(${v.thumbnail.url})` }}
        />
        <motion.div
          className="work__info-frame"
          layoutId={`work__info-frame--${id}`}
        >
          <time className="work__date">{dateFormat(v.projectAt)}</time>
          <br />
          <h3 className="work__title">{v.title}</h3>
        </motion.div>
        <motion.div className="work__description-wrap" animate>
          <p
            className="work__description"
            dangerouslySetInnerHTML={{ __html: v.description }}
          />
          <a
            className="work__button"
            href={v.url}
            target="_blank"
            rel="noreferrer"
          >
            go to site
          </a>
        </motion.div>
      </motion.div>
    </>
  );
}

function WorksList() {
  const { data: works = [] } = useQuery('works', () => getWorks());
  return (
    <ul className="works__list">
      {works &&
        works.contents.map((v) => {
          return <Work {...v} key={v.title} />;
        })}
    </ul>
  );
}

export default function Works() {
  const workId = useRecoilValue(selectWorkId);

  return (
    <motion.main
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ ease: 'easeOut', duration: 0.4 }}
      className="layout__main"
    >
      <Helmet>
        <title>Works</title>
      </Helmet>
      <div className="works">
        <AnimateSharedLayout>
          <h1 className="works__main-title">Works</h1>
          <Suspense fallback={<NotItems styleClass={'works__list'} />}>
            <WorksList />
          </Suspense>
          <AnimatePresence>
            {workId && <SelectedWork id={workId} key="item" />}
          </AnimatePresence>
        </AnimateSharedLayout>
      </div>
    </motion.main>
  );
}
