import { Link, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { navigationStatus } from '../../state/header';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import navigationItems from '../../data/navigation';
import './header.scss';

export default function Header() {
  const [naviStatus, setNaviStatus] = useRecoilState(navigationStatus);
  const location = useLocation();
  const navigationList = navigationItems();

  const switchNavigation = (status) => {
    setNaviStatus(status);
  };

  const closeNavigation = () => {
    setNaviStatus(false);
  };

  return (
    <header className="header">
      <div className="header__wrap">
        <h1>
          <Link to="/">
            <img
              className="header__title"
              src="/images/logo.svg"
              alt="sotobayashi.work"
            />
          </Link>
        </h1>
        <nav className="header__navigation">
          <ul className="header__navigation-list">
            <AnimateSharedLayout>
              {navigationList.map((item) => {
                const isActive = location.pathname === item.href;
                return (
                  <li
                    key={item.href}
                    className={`header__navigation-item ${
                      isActive && 'header__navigation-item--selected'
                    }`}
                  >
                    <Link
                      to={item.href}
                      className={`header__navigation-link ${
                        isActive && 'header__navigation-link--selected'
                      }`}
                    >
                      {item.name}
                    </Link>
                    {isActive && (
                      <motion.div
                        layoutId="header__navigation-background"
                        className="header__navigation-background"
                        initial={false}
                        transition={{ ease: 'easeOut', duration: 0.4 }}
                      />
                    )}
                  </li>
                );
              })}
            </AnimateSharedLayout>
          </ul>
        </nav>
        <div className="header-sp">
          <div
            className="header-sp__navigation-button"
            onClick={() => switchNavigation(!naviStatus)}
          >
            <div
              className={`header-sp__navigation-line ${
                naviStatus && 'header-sp__navigation-line--on'
              }`}
            />
            <div
              className={`header-sp__navigation-line ${
                naviStatus && 'header-sp__navigation-line--on'
              }`}
            />
            <div
              className={`header-sp__navigation-line ${
                naviStatus && 'header-sp__navigation-line--on'
              }`}
            />
          </div>
          <AnimatePresence>
            {naviStatus && (
              <motion.div
                key="header-sp__navigation-bg"
                className="header-sp__navigation-bg"
                initial={{ scale: 1 }}
                animate={{ scale: 150 }}
                exit={{ scale: 1 }}
                transition={{ ease: 'easeOut', duration: 1 }}
              />
            )}
            {naviStatus && (
              <motion.nav
                key="header-sp__navigation"
                className="header-sp__navigation"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <ul className="header-sp__navigation-list">
                  {navigationList.map((item, i) => {
                    console.log(`${item.href}-${i}-sp`);
                    return (
                      <li
                        key={`${item.href}-${i}-sp`}
                        className={`header-sp__navigation-item`}
                      >
                        <Link
                          key={`${item.href}-${i}-sp`}
                          to={item.href}
                          className={`header-sp__navigation-link`}
                          onClick={() => closeNavigation()}
                        >
                          {item.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </motion.nav>
            )}
          </AnimatePresence>
        </div>
      </div>
    </header>
  );
}
