import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import Top from '../components/pages';
import Profile from '../components/pages/profile';
import Works from '../components/pages/works';
import Contact from '../components/pages/contact';
import Libraries from '../components/pages/libraries';
import ScrollTop from './scrollTop';

export default function RouterDefinition() {
  const location = useLocation();
  const rootPath = location.pathname.split('/');
  return (
    <>
      <ScrollTop />
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={rootPath[1]}>
          <Route path="/libraries">
            <Libraries />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/works">
            <Works />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <Top />
          </Route>
        </Switch>
      </AnimatePresence>
    </>
  );
}
