import { Suspense } from 'react';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { getLibraries } from '../../../api/libraries';
import { NotItems } from '../../organisms/not-items';
import './libraries.scss';

function LibrariesInfo() {
  const { data: libraries = [] } = useQuery('libraries', () => getLibraries());
  return (
    <dl className="library__list">
      {libraries &&
        libraries.contents.map((p, i) => {
          return (
            <li className="library__item" key={i}>
              <dt className="library__item-title">{p.name}</dt>
              <dd className="library__item-data">{p.version}</dd>
            </li>
          );
        })}
    </dl>
  );
}

export default function Libraries() {
  return (
    <motion.main
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ ease: 'easeOut', duration: 0.4 }}
      className="layout__main"
    >
      <Helmet>
        <title>Libraries</title>
      </Helmet>
      <div className="library">
        <h1 className="library__main-title">Libraries</h1>
        <div className="library__status-wrap">
          <div className="library__status-frame">
            <Suspense fallback={<NotItems styleClass={'library__list'} />}>
              <LibrariesInfo />
            </Suspense>
          </div>
        </div>
      </div>
    </motion.main>
  );
}
