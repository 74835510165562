import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import './index.scss';

export default function Home({ allPostsData }) {
  return (
    <motion.main
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ ease: 'easeOut', duration: 0.4 }}
      className="layout__main"
    >
      <Helmet>
        <title>TOP</title>
      </Helmet>
      <section className="top">
        <div className="top__wrap">
          <h1 className="top__title">
            <span className="top__title--small">Welcome to</span>
            <br />
            Sotobayashi's
            <br />
            Portfolio
            <br />
            Site
          </h1>
        </div>
      </section>
    </motion.main>
  );
}
