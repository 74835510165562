import camelcaseKeys from 'camelcase-keys';

export const DEFAULT_API_OPTIONS = {
  prefixUrl: 'https://sotobayashi-portfolio.microcms.io/api/v1/',
  timeout: 7000,
  retry: 2,
  headers: {
    'X-API-KEY': '5b25186a-6d5c-4496-a15c-5eb67319959a',
  },
  hooks: {
    afterResponse: [
      async (_request, _options, response) => {
        const body = new Blob(
          [JSON.stringify(camelcaseKeys(await response.json()), null, 2)],
          { type: 'application/json' }
        );
        const { headers, status, statusText } = response;
        const init = { headers, status, statusText };

        return new Response(body, init);
      },
    ],
  },
};
