import { Suspense } from 'react';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { getProfile } from '../../../api/profile';
import { NotItems } from '../../organisms/not-items';
import './profile.scss';

function ProfileInfo() {
  const { data: profile = [] } = useQuery('profile', () => getProfile());
  return (
    <dl className="profile__list">
      {profile &&
        profile.contents.map((p, i) => {
          return (
            <li className="profile__item" key={i}>
              <dt className="profile__item-title">{p.title}</dt>
              <dd className="profile__item-data">{p.detail}</dd>
            </li>
          );
        })}
    </dl>
  );
}

export default function Profile() {
  return (
    <motion.main
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ ease: 'easeOut', duration: 0.4 }}
      className="layout__main"
    >
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <div className="profile">
        <h1 className="profile__main-title">Profile</h1>
        <div className="profile__status-wrap">
          <div className="profile__status-frame">
            <div className="profile__image-wrap">
              <img
                className="profile__image"
                src="/images/sotobayashi.jpg"
                alt="外林洋（ソトバヤシ　ヒロシ）"
              />
            </div>
            <Suspense fallback={<NotItems styleClass={'profile__list'} />}>
              <ProfileInfo />
            </Suspense>
          </div>
        </div>
      </div>
    </motion.main>
  );
}
